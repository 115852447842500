import React from "react";
import { Outlet } from "react-router-dom";

import PDFViewer from "@/components/PDFViewer";
import useDocument from "@/hooks/useDocument";

import {
  StyledContainer,
  StyledContentContainer,
} from "./UploadDocument.styles";

const UploadDocument: React.FC = () => {
  const { documentBase64St } = useDocument();

  return (
    <StyledContainer data-testid='upload-document-component'>
      <StyledContentContainer>
        <PDFViewer base64={documentBase64St as string} />
      </StyledContentContainer>
      <Outlet />
    </StyledContainer>
  );
};

export default UploadDocument;
