export interface IUserDocumentStatusDataGrid {
  actions: string | ((id?: string) => void);
  id: string;
  procedure: {
    documentType: string;
    documentSignatureType: string;
  };
  status: string;
}

export interface IUserDocumentDataResponse {
  code: number;
  status: string;
  data: Data;
}

export interface Data {
  contracts: Contract[];
}

export interface Contract {
  contractID: string;
  ownerType: string;
  status: string;
  paymentStatus: string;
  contractOwner: string;
  numberID: string;
  signedCount: number;
  createdAt: string;
}

export enum EDocumentPaymentStatus {
  PENDING = "Contrato pendiente de pago",
  PAIDOUT = "Contrato pagado",
}

export enum EDocumentStatus {
  CREATED = "Contrato creado",
  SIGNED = "Contrato firmado",
  REVIEW = "Contrato en review",
  SIGNED_PENDING = "Contrato en revisión",
}

export enum EDocumentStatusColor {
  CREATED = "ligth",
  SIGNED = "Contrato firmado",
  SIGNED_PENDING = "Contrato en revisión",
}
