import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import { ReactComponent as Logo } from "@/assets/logos/fv-logo-reg.svg";
import { theme } from "@/theme";

import {
  StyledFormContainer,
  StyledShowroomContainer,
} from "./Sessions.styles";

const Sessions: React.FC = () => {
  return (
    <Grid
      container
      data-testid='sessions-page'
      direction={{ xs: "column", md: "row" }}
      height={{ md: "100vh" }}
    >
      <Grid
        item
        xs={12}
        md={5}
        display='flex'
        justifyContent='center'
        alignItems='center'
        px={theme.spacing(4)}
      >
        <Stack spacing={10} width='100%'>
          <Box display='flex' justifyContent='center'>
            <Logo height={40} />
          </Box>
          <StyledFormContainer>
            <Outlet />
          </StyledFormContainer>
        </Stack>
      </Grid>
      <StyledShowroomContainer
        item
        xs={12}
        md={7}
        overflow='hidden'
      ></StyledShowroomContainer>
    </Grid>
  );
};

export default Sessions;
