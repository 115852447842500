import { Box, BoxProps, styled } from "@mui/material";

interface IStatusBadgeProps extends BoxProps {
  verified: boolean;
}

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
}));

export const StyledStatusBadge = styled(Box)<IStatusBadgeProps>(
  ({ theme, verified }) => ({
    position: "absolute",
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 9999,
    top: "50%",
    left: 0,
    transform: "translate(-50%, -50%)",
    backgroundColor: verified
      ? theme.palette.grass?.main
      : theme.palette.autumn?.light,
  }),
);
