import React from "react";

import Header from "@/components/Header";
import SideMenu from "@/components/SideMenu";

import {
  StyledContainer,
  StyledContentContainer,
  StyledMenuAndContentContainer,
  StyledMenuContainer,
} from "./Layout.styles";
import { ILayoutProps } from "./Layout.types";

const Layout: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <StyledContainer data-testid='layout-component'>
      <Header />
      <StyledMenuAndContentContainer>
        <StyledMenuContainer>
          <SideMenu />
        </StyledMenuContainer>
        <StyledContentContainer>{children}</StyledContentContainer>
      </StyledMenuAndContentContainer>
    </StyledContainer>
  );
};

export default Layout;
