import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  styled,
} from "@mui/material";

export const StyledFormContentContainer = styled(Box)<BoxProps>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    flex: 1,
    width: "100%",
  }),
);

export const StyledFormInputContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  justifyContent: "center",
  alignItems: "flex-start",
  gap: theme.spacing(2),
  padding: `${theme.spacing(2)} 0`,
}));

export const StyledFormRoleInputContainer = styled(Box)<BoxProps>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    gap: theme.spacing(2),
    width: "60%",
    margin: "0 auto",
  }),
);

export const StyledFormSignerRolesContainer = styled(Box)<BoxProps>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    gap: theme.spacing(2),
  }),
);

export const StyledFormIconButton = styled(IconButton)<IconButtonProps>(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.grey[300]}!important`,
    borderRadius: `${theme.shape.borderRadius}px!important`,
    width: theme.spacing(6),
    height: theme.spacing(6),
  }),
);
