import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import DocumentDetailData from "@/components/DocumentDetailData";
import {
  Contract,
  ESignerVerification,
  Signer,
  File,
} from "@/components/DocumentDetailData/DocumentDetailData.types";
import DocumentSignerItem from "@/components/DocumentSignerItem";
import { useLazyGetOneDocumentQuery } from "@/service";

const DocumentDetail: React.FC = () => {
  const params = useParams();
  const [getDocument, { data, isSuccess }] = useLazyGetOneDocumentQuery();
  const [updateContract, setUpdateContract] = useState<Contract | null>(null);
  const [updateSigners, setUpdateSigners] = useState<Signer[] | null>(null);
  const [updateFiles, setUpdateFiles] = useState<File[] | null>(null);

  useEffect(() => {
    getDocument(params.contractid);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setUpdateContract(data.data.contrato);
      setUpdateSigners(data.data.firmantes);
      setUpdateFiles(data.data.archivos);
    }
  }, [isSuccess]);

  const isVerified = (signer: Signer): boolean => {
    if (signer.verification.length) {
      return signer.verification[0].status === ESignerVerification.FINISH;
    }

    return false;
  };

  return (
    <Box data-testid='document-detail-page' width='100%'>
      {data && (
        <Grid container gap={2}>
          <Grid item xs={8}>
            {updateContract && updateSigners && updateFiles ? (
              <DocumentDetailData
                contract={updateContract}
                files={updateFiles}
                signers={updateSigners}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={3}>
            <Stack spacing={2}>
              {data.data.firmantes.map((signer: Signer, index: number) => {
                const verified = isVerified(signer);
                return (
                  <DocumentSignerItem
                    key={`${signer.signerID}_${index}`}
                    email={signer.email}
                    signer={index + 1}
                    rol={Number(signer.rol)}
                    verified={verified}
                    payment={signer.payment}
                    contractId={signer.contractID}
                    signerId={signer.signerID}
                    linkPayment={signer.link_payment}
                    paymentStatus={updateContract?.paymentStatus}
                    portion={signer.portion}
                  />
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DocumentDetail;
