import { Box, BoxProps, List, ListProps, styled } from "@mui/material";

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  padding: `${theme.spacing(8)} 0`,
  boxSizing: "border-box",
  borderRadius: theme.shape.borderRadius,
  gap: theme.spacing(7),
  flex: 1,
}));

export const StyledSignersContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flex: 1,
  width: "100%",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}));

export const StyledSignersTitleContainer = styled(Box)<BoxProps>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: `0 ${theme.spacing(6)}`,
  }),
);

export const StyledSignersListContainer = styled(List)<ListProps>(() => ({
  width: "100%",
}));
