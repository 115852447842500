import { useDispatch, useSelector } from "react-redux";

import { userSelector, tokenSelector } from "@/state/Session/selectors";
import { endSession as endSessionAction } from "@/store";

const useSession = () => {
  const dispatch = useDispatch();
  const sessionUserSt = useSelector(userSelector);
  const sessionTokenSt = useSelector(tokenSelector);

  const endSession = () => dispatch(endSessionAction());

  return {
    sessionUserSt,
    sessionTokenSt,
    endSession,
  };
};

export default useSession;
