import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISessionState } from "./slice.types";

const initialState: ISessionState = {
  id: null,
  phone: null,
  rfc: null,
  rol: null,
  status: null,
  username: null,
  email: null,
  name: null,
  apPaterno: null,
  apMaterno: null,
  userID: null,
  token: null,
  error: false,
};

export const sessionSlice = createSlice({
  initialState,
  name: "session",
  reducers: {
    setUserSession: (
      state: ISessionState,
      { payload }: PayloadAction<ISessionState>,
    ) => {
      state.name = payload.name;
      state.apMaterno = payload.apMaterno;
      state.apPaterno = payload.apPaterno;
      state.email = payload.email;
      state.id = payload.id;
      state.phone = payload.phone;
      state.rfc = payload.rfc;
      state.rol = payload.rol;
      state.status = payload.status;
      state.userID = payload.userID;
      state.username = payload.username;
      state.token = payload.token;
    },
    setError: (
      state: ISessionState,
      { payload }: PayloadAction<ISessionState["error"]>,
    ) => {
      state.error = payload;
    },
  },
});

export const { setUserSession, setError } = sessionSlice.actions;
