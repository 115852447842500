import * as yup from "yup";

const phoneRegExp = /^(\+52)?\s?\d{10}$/;
const rfcRegex = /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/;

export const validationSchema = yup.object({
  rfc: yup
    .string()
    .matches(rfcRegex, "RFC inválido")
    .required("Este campor es requerido"),
  fullName: yup
    .string()
    .min(2)
    .max(255)
    .required("Por favor introduce tus nombres"),
  fatherLastName: yup
    .string()
    .min(2)
    .max(255)
    .required("Por favor introduce tu apellido paterno"),
  motherLastName: yup
    .string()
    .min(2)
    .max(255)
    .required("Por favor introduce tu apellido materno"),
  phoneNumber: yup.string().matches(phoneRegExp, "Número telefónico no válido"),
  email: yup
    .string()
    .email()
    .required("Por favor introduce tu correo electrónico"),
});
