export enum EServiceStatus {
  SUCCESS = "success",
  FAILURE = "failure",
}

export interface IServiceErrorSatus {
  error:
    | {
        message: string | null;
        code: string | number | null;
      }
    | boolean
    | null;
}

export interface IValidationProps {
  contract_id: string;
  signerr_id: string;
  verification_code: string;
}

export interface IValidationIdProps {
  file: string;
  imgIdentityFront: string;
  imgIdentityBack: string;
  contractID: string;
  signerID: string;
}

export interface ISessionsLoginProps {
  email: string;
  password: string;
}

export interface ISessionsCreateUserProps {
  rfc: string;
  name: string;
  apPaterno: string;
  apMaterno: string;
  phone: string;
  email: string;
  password: string;
  username?: string;
}

export interface ICreateDocumentProps {
  contract_type_fee_id: number;
  contract_owner: string;
  signed_count: number;
  owner_type: string;
  payment_service: number;
  file: string;
  page_sign: number;
  signers: Signer[];
}

export interface Signer {
  rfc: string;
  name: string;
  apMaterno: string;
  apPaterno: string;
  email: string;
  rol: number;
  sign_status: string;
  signer_type: string;
  portion: number;
}
