import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Field, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useLoader, useSession } from "@/hooks";
import { useUpdateUserMutation } from "@/service";
import { theme } from "@/theme";

import { validationSchema } from "./UpdateUser.schema";
import { StyledContainer } from "./UpdateUser.styles";

const UpdateUser: React.FC = () => {
  const { sessionUserSt } = useSession();
  const navigation = useNavigate();
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const { setLoader } = useLoader();

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  return (
    <StyledContainer item data-testid='update-user-info-component'>
      <Typography
        variant='h5'
        align='center'
        color='primary'
        mb={theme.spacing(6)}
      >
        Actualizar información de usuario
      </Typography>
      <Box width={{ xs: "100%", md: "70%", lg: "50%" }}>
        <Formik
          initialValues={{
            rfc: sessionUserSt.rfc,
            fullName: sessionUserSt.name,
            fatherLastName: sessionUserSt.apPaterno,
            motherLastName: sessionUserSt.apMaterno,
            phoneNumber: sessionUserSt.phone,
            email: sessionUserSt.email,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            updateUser({
              rfc: values.rfc,
              name: values.fullName,
              apPaterno: values.fatherLastName,
              apMaterno: values.motherLastName,
              phone: values.phoneNumber,
              email: values.email,
            });

            setSubmitting(false);
          }}
        >
          {({ submitForm, isSubmitting }) => {
            return (
              <Stack spacing={4}>
                <Stack spacing={3}>
                  <Field
                    component={TextField}
                    label='Nombre completo'
                    name='fullName'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    label='Apellido paterno'
                    name='fatherLastName'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    label='Apellido materno'
                    name='motherLastName'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    label='RFC'
                    name='rfc'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    label='Número de teléfono'
                    name='phoneNumber'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    label='Correo electrónico'
                    name='email'
                    fullWidth
                  />
                </Stack>
                <Button
                  variant='contained'
                  size='medium'
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Actualizar
                </Button>
                <Button
                  variant='text'
                  size='medium'
                  onClick={() => navigation(-1)}
                  startIcon={<ArrowBack />}
                >
                  Atrás
                </Button>
              </Stack>
            );
          }}
        </Formik>
      </Box>
    </StyledContainer>
  );
};

export default UpdateUser;
