import { Box, BoxProps, styled } from "@mui/material";

export const StyledMenuContainer = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flex: 3,
  },
  [theme.breakpoints.up("md")]: {
    flex: 2,
  },
}));

export const StyledMenuAndContentContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flex: 1,
  boxSizing: "border-box",
}));

export const StyledContentContainer = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 9,
  padding: theme.spacing(2),
}));

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.almostwhite?.main,
  height: "100vh",
}));
