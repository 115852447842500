import { Person } from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { GridRow, GridRowProps, useGridApiContext } from "@mui/x-data-grid";
import React, { useState } from "react";

import { theme } from "@/theme";

const UserDocumentStatusRow: React.FC<GridRowProps> = props => {
  const apiRef = useGridApiContext();
  const [details, setDetails] = useState<{
    id: string;
    signersCount: number;
    createdAt: string;
    paymentStatus: string;
  } | null>(null);

  const handleRowMoreInfo = () => {
    const row = apiRef.current.getRow(props.rowId);
    if (details?.id === row.id) {
      setDetails(null);
    } else {
      setDetails({
        id: row.id,
        createdAt: row.createdAt,
        paymentStatus: row.paymentStatus,
        signersCount: row.signersCount,
      });
    }
  };

  return (
    <>
      <GridRow {...props} onClick={handleRowMoreInfo} />
      {details && (
        <Box
          height={70}
          bgcolor={theme.palette.almostwhite?.main}
          p={theme.spacing(2)}
        >
          <Grid container direction='row' gap={4}>
            <Grid item>
              <Stack spacing={1}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  fontSize={theme.spacing(1.6)}
                >
                  Fecha
                </Typography>
                <Typography variant='body1' fontSize={theme.spacing(1.6)}>
                  {details.createdAt}
                </Typography>
              </Stack>
            </Grid>
            <Grid item>
              <Stack spacing={1}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  fontSize={theme.spacing(1.6)}
                >
                  ¿Documentos descargados?
                </Typography>
                <Typography variant='body1' fontSize={theme.spacing(1.6)}>
                  N/A
                </Typography>
              </Stack>
            </Grid>
            <Grid item>
              <Stack spacing={1}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  fontSize={theme.spacing(1.6)}
                >
                  Participantes
                </Typography>
                <Stack spacing={1} direction='row' alignItems='center'>
                  <Typography variant='body1' fontSize={theme.spacing(1.6)}>
                    {details.signersCount}
                  </Typography>
                  <Person sx={{ fill: theme.palette.atmosphere?.main }} />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default UserDocumentStatusRow;
