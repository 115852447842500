import {
  Check,
  CreditCard,
  Email,
  Person,
  Schedule,
  Sync,
  Videocam,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  lighten,
  styled,
} from "@mui/material";
import React, { useEffect } from "react";

import { useLoader, useMessager } from "@/hooks";
import {
  useLazyForwardEmailQuery,
  useLazyDownloadVideoQuery,
  useLazyGetOneDocumentQuery,
} from "@/service";
import { EDocumentSignerRoles } from "@/state/Document/slice.types";
import { theme } from "@/theme";

import {
  StyledContainer,
  StyledStatusBadge,
} from "./DocumentSignerItem.styles";
import { IDocumentSignerItemProps } from "./DocumentSignerItem.types";

const DocumentSignerItem: React.FC<IDocumentSignerItemProps> = ({
  email,
  signer,
  verified,
  rol,
  payment,
  contractId,
  signerId,
  linkPayment,
  setFiles,
  seContract,
  setSigners,
  paymentStatus,
  portion
}) => {
  const [
    forwardEmail,
    { isSuccess: successForwardEmail, isLoading: loadingForwardEmail },
  ] = useLazyForwardEmailQuery();
  const [downloadVideo, { data, isLoading: loadingVideo }] =
    useLazyDownloadVideoQuery();
  const [getDocument, { isLoading: loadingDocument }] =
    useLazyGetOneDocumentQuery();
  const { setLoader } = useLoader();
  const { setMessage } = useMessager();

  useEffect(() => {
    if (!loadingVideo && data) {
      const flashLink = document.createElement("a");
      flashLink.href = `data:video/mp4;base64,${data[0].video}`;
      flashLink.download = `agreement_${contractId}_${signerId}.mp4`;
      document.body.appendChild(flashLink);
      flashLink.click();
      document.body.removeChild(flashLink);
    }
  }, [data, loadingVideo]);

  useEffect(() => {
    if (successForwardEmail) {
      setMessage({
        title: "Correo de veriricación enviado",
        body: "Se ha reenviado el correo de verificación junto con la solicitud de firma para este contrato. Por favor, revise su correo electrónico o informe a los involucrados.",
        buttonCloseTitle: "Entendido",
        open: true,
        type: "modal",
      });
    }
  }, [successForwardEmail]);

  useEffect(() => {
    setLoader(loadingDocument || loadingForwardEmail);
  }, [loadingDocument, loadingForwardEmail]);

  const StyledPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    height: theme.spacing(6),
    lineHeight: theme.spacing(6),
    position: "relative",
    borderWidth: 1,
    borderStyle: "solid",
    padding: `0 ${theme.spacing(4)}`,
    borderColor: verified
      ? theme.palette.grass?.main
      : theme.palette.autumn?.light,
    backgroundColor: verified
      ? lighten(theme.palette.grass?.main ?? "", 0.8)
      : lighten(theme.palette.autumn?.light ?? "", 0.8),
  }));

  const participant = () => {
    switch (rol) {
      case EDocumentSignerRoles.PAYER:
        return "Pagador";
      case EDocumentSignerRoles.SIGNER:
        return "Firmante";
      case EDocumentSignerRoles.PAYER_AND_SIGNER:
        return "Firmante y Pagador";
    }
  };

  const participanTypeStatus = () => {
    switch (rol) {
      case EDocumentSignerRoles.PAYER:
        return (
          <Box display='flex' justifyContent='space-between' gap={1}>
            <Typography variant='body2' fontWeight='bold'>
              Monto a pagar
            </Typography>
            <Typography variant='body2'>{`$${payment}`}</Typography>
          </Box>
        );
      case EDocumentSignerRoles.SIGNER:
        return (
          <StyledPaper elevation={2}>
            <StyledStatusBadge verified={verified}>
              {verified ? (
                <Check sx={{ fill: "white" }} />
              ) : (
                <Schedule sx={{ fill: "white" }} />
              )}
            </StyledStatusBadge>
            <Typography variant='body2'>Validación biométrica</Typography>
          </StyledPaper>
        );
      case EDocumentSignerRoles.PAYER_AND_SIGNER:
        return (
          <StyledPaper elevation={2}>
            <StyledStatusBadge verified={verified}>
              {verified ? (
                <Check sx={{ fill: "white" }} />
              ) : (
                <Schedule sx={{ fill: "white" }} />
              )}
            </StyledStatusBadge>
            <Typography variant='body2'>Validación biométrica</Typography>
          </StyledPaper>
        );
    }
  };

  const update = async (id: string) => {
    const res = await getDocument(id);
    if (res.isSuccess) {
      seContract && seContract(res.data.contrato);
      setSigners && setSigners(res.data.firmantes);
      setFiles && setFiles(res.data.archivos);
    }
  };

  const sendPaymentRequest = () => {
    linkPayment && window.open(linkPayment[0].link_pay, "_blank");
  };

  return (
    <StyledContainer data-testid='document-signer-item-component'>
      <Stack
        position='absolute'
        top={0}
        left={0}
        width='100%'
        justifyContent='space-between'
        alignItems='center'
        direction='row'
        paddingX={theme.spacing(2)}
        paddingY={theme.spacing(1)}
        boxSizing='border-box'
      >
        <Chip label={participant()} color='default' size='small' />
        <IconButton onClick={() => update(contractId)} size='small'>
          <Sync />
        </IconButton>
      </Stack>
      <Stack justifyContent='center' alignItems='center' spacing={2}>
        <Avatar>
          <Person fontSize='medium' />
        </Avatar>
        <Typography
          variant='body1'
          fontWeight='bold'
          color='primary'
        >{`Participante ${signer}`}</Typography>
        <Typography variant='body1' fontWeight='bold'>
          {email}
        </Typography>
        <Grid container justifyContent='center' alignItems='center' gap={1}>
          <Grid item justifyContent='space-between'>
            {participanTypeStatus()}
          </Grid>
          <Grid item>
            {(rol === EDocumentSignerRoles.SIGNER || rol === EDocumentSignerRoles.PAYER_AND_SIGNER) && (
              <Grid container item direction='row'>
                {(!verified && paymentStatus === 'PAIDOUT') && (
                  <Grid item>
                    <Tooltip title='Reenviar correo de verificación'>
                      <IconButton
                        onClick={() => forwardEmail({ contractId, signerId })}
                      >
                        <Email
                          fontSize='medium'
                          sx={{ fill: theme.palette.atmosphere?.main }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                {(verified) && (
                  <Grid item>
                    <Tooltip title='Descargar video de verificación'>
                      <IconButton
                        onClick={() => downloadVideo({ contractId, signerId })}
                      >
                        <Videocam
                          fontSize='medium'
                          sx={{ fill: theme.palette.atmosphere?.main }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          {((rol === EDocumentSignerRoles.PAYER || rol === EDocumentSignerRoles.PAYER_AND_SIGNER) || (paymentStatus === 'PENDING' && portion !== '0')) && (
            <Grid item xs={12}>
              <Grid container gap={2} justifyContent='center' alignItems='center'>
                <Grid item>
                  <Button
                    variant='contained'
                    startIcon={<CreditCard />}
                    disabled={!linkPayment?.length}
                    onClick={sendPaymentRequest}
                    fullWidth
                  >
                    Pagar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Stack>
    </StyledContainer>
  );
};

export default DocumentSignerItem;
