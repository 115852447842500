import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Box,
  ListItemIcon,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as LogoutIcon } from "@/assets/icons/log-out-icon.svg";
import NavigationMenuItem from "@/components/NavigationMenuItem/NavigationMenuItem";
import { useMessager, useSession } from "@/hooks";
import { theme } from "@/theme";

import { menuItems } from "./NavigationMenu.map";
import { StyledContainer } from "./NavigationMenu.styles";
import UserSettings from "../UserSettings";

const NavigationMenu: React.FC = () => {
  const navigation = useNavigate();
  const { endSession } = useSession();
  const { setMessage } = useMessager();
  const [openMenuId, setOpenMenuId] = useState<null | string>(null);
  const refs = menuItems.reduce(
    (acc, item) => {
      acc[item.id] = React.createRef<HTMLDivElement>();
      return acc;
    },
    {} as Record<string, React.RefObject<HTMLDivElement>>,
  );

  const handleItemClick = (id: string) => {
    switch (id) {
      case "user-settings":
        setMessage({
          title: "Información de perfil",
          body: <UserSettings />,
          buttonCloseTitle: "Cerrar",
          open: true,
          type: "modal",
          extraButton: true,
          extraButtonTitle: "Editar información",
          handleExtraButtonClick: () => navigation("user-settings/update"),
        });
        break;
      default:
        setOpenMenuId(openMenuId !== id ? id : null);
        break;
    }
  };

  const handleSessionClose = () => {
    endSession();
    navigation("/session/login");
  };

  const handleEndSession = () => {
    setMessage({
      title: "¿Deseas terminar tu sesión?",
      body: (
        <Alert severity='warning'>
          Si estas creando un contrato, ten en cuenta que perderá el progreso
          realizado.
        </Alert>
      ),
      buttonCloseTitle: "Si",
      open: true,
      type: "modal",
      extraButton: true,
      extraButtonTitle: "No",
      onClose: () => handleSessionClose(),
    });
  };

  const handleNavigation = (path: string) => navigation(path);

  return (
    <StyledContainer
      component='nav'
      aria-labelledby='nested-list-subheader'
      data-testid='navigation-menu-component'
    >
      <Box>
        {menuItems.map(menu => (
          <Box key={menu.id}>
            <NavigationMenuItem
              ref={refs[menu.id]}
              primaryText={menu.text}
              icon={menu.icon}
              onClick={() =>
                menu.link
                  ? handleNavigation(menu.link)
                  : handleItemClick(menu.id)
              }
              expanded={openMenuId === menu.id}
              showExpandIcon={Boolean(menu.subMenu.length)}
            />
            <Collapse in={openMenuId === menu.id} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {menu.subMenu.map(submenu => (
                  <ListItemButton
                    key={submenu.id}
                    sx={{ pl: 4 }}
                    onClick={() => navigation(submenu?.to)}
                  >
                    <ListItemText
                      primary={submenu.text}
                      primaryTypographyProps={{
                        color: theme.palette.grey[700],
                      }}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </Box>
        ))}
      </Box>
      <List component='div' disablePadding>
        <ListItemButton sx={{ pl: 4 }} onClick={handleEndSession}>
          <ListItemIcon>
            <LogoutIcon color='primary' width={20} height={20} />
          </ListItemIcon>
          <ListItemText
            primary='Cerrar sesión'
            primaryTypographyProps={{
              color: theme.palette.common.black,
              fontWeight: "bold",
            }}
          />
        </ListItemButton>
      </List>
    </StyledContainer>
  );
};

export default NavigationMenu;
