import { Box, BoxProps, lighten, styled } from "@mui/material";

interface ExtendedBox extends BoxProps {
  isselected: boolean;
}

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  boxSizing: "border-box",
  borderRadius: theme.shape.borderRadius,
  maxWidth: theme.spacing(30),
  gap: theme.spacing(3),
}));

export const StyledIconContainer = styled(Box)<ExtendedBox>(({
  theme,
  isselected,
}) => {
  const additional = () =>
    isselected
      ? {
          backgroundColor: lighten(theme.palette.primary.light, 0.7),
          borderColor: theme.palette.primary.light,
        }
      : null;
  return {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...additional(),
  };
});

export const StyledInfoContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: theme.spacing(20),
}));
