import { Box, BoxProps, styled } from "@mui/material";
import { Form, FormikFormProps } from "formik";

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
}));

export const StyledFormInputsContainer = styled(Form)<FormikFormProps>(
  ({ theme }) => ({
    gap: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  }),
);

export const StyledInputContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  justifyContent: "center",
  alignItems: "flex-end",
}));
