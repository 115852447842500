import { IPDFSignsPosition } from "./PDFSignersPage.types";

export const signersPosition: IPDFSignsPosition[] = [
  { PosX: 10, PosY: 100 },
  { PosX: 298, PosY: 100 },
  { PosX: 10, PosY: 200 },
  { PosX: 298, PosY: 200 },
  { PosX: 10, PosY: 300 },
  { PosX: 298, PosY: 300 },
  { PosX: 10, PosY: 400 },
  { PosX: 298, PosY: 400 },
  { PosX: 10, PosY: 500 },
  { PosX: 298, PosY: 500 },
];
