import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { useMessager, useLoader } from "@/hooks";
import {
  useDeleteDocumentMutation,
  useLazyGetAllDocumentsQuery,
} from "@/service";

import { colDefs } from "./UserDocumentStatus.map";
import { StyledContainer } from "./UserDocumentStatus.styles";
import {
  IUserDocumentStatusDataGrid,
  IUserDocumentDataResponse,
  Contract,
} from "./UserDocumentStatus.types";
import CustomRow from "./UserDocumentStatusRow";
import CustomToolbar from "./UserDocumentStatusToolbar";

const UserDocumentStatus: React.FC = () => {
  const data = useOutletContext<QueryReturnValue>();
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState<IUserDocumentStatusDataGrid[] | null>(null);

  const { setMessage } = useMessager();
  const { setLoader } = useLoader();
  const [deleteDocument, { isLoading, isSuccess }] =
    useDeleteDocumentMutation();
  const [getAll] = useLazyGetAllDocumentsQuery();

  useEffect(() => {
    setLoader(isLoading);
    isSuccess && getAll();
  }, [isLoading, isSuccess]);

  const openDeleteModal = (contractId: string) => {
    setMessage({
      title: "¿Deseas eliminar este contrato?",
      body: "Ten en cuenta que habrás perdido todos los documentos relacionados con este contrato, así como las validaciones de identidad y firmas de los participantes.",
      open: true,
      buttonCloseTitle: "No",
      type: "modal",
      extraButton: true,
      extraButtonTitle: "Si",
      handleExtraButtonClick: () => deleteDocument(contractId),
    });
  };

  const handleDocumentsData = (documents: IUserDocumentDataResponse) => {
    if (documents) {
      const docs = documents.data.contracts;
      const docsAsDataGrid: IUserDocumentStatusDataGrid[] = docs.map(
        (doc: Contract) => ({
          actions: () => openDeleteModal(doc.contractID),
          id: doc.numberID,
          date: doc.createdAt,
          procedure: {
            documentType: "Contrato de arriendo",
            documentSignatureType: "Firma con certificación notarial",
          },
          status: doc.status,
          createdAt: doc.createdAt,
          signersCount: doc.signedCount,
          paymentStatus: doc.paymentStatus,
          contractId: doc.contractID,
        }),
      );

      setRows(docsAsDataGrid);
    }
  };

  useEffect(() => {
    data && handleDocumentsData(data as IUserDocumentDataResponse);
  }, [data]);

  return (
    <>
      <StyledContainer data-testid='user-documents-status-component'>
        <DataGrid
          apiRef={apiRef}
          columns={colDefs}
          rows={rows ?? []}
          pageSizeOptions={[20]}
          disableRowSelectionOnClick
          sx={{ borderWidth: 0 }}
          slots={{
            toolbar: CustomToolbar,
            row: CustomRow,
          }}
          localeText={{
            filterOperatorContains: "Contiene",
            filterOperatorEquals: "Igual",
            filterOperatorStartsWith: "Comienza con",
            filterOperatorEndsWith: "Termina con",
            filterOperatorIsEmpty: "Está vacío",
            filterOperatorIsNotEmpty: "No esta vacío",
            filterOperatorIsAnyOf: "Es cualquiera de",
            filterPanelOperator: "Criterio",
            filterPanelColumns: "Columnas",
            filterPanelInputLabel: "Valor",
            filterPanelInputPlaceholder: "Ingresa un valor",
            columnMenuSortAsc: "Ordenar ASC",
            columnMenuSortDesc: "Ordenar DESC",
            columnMenuHideColumn: "Ocultar columna",
            columnMenuManageColumns: "Gestionar columnas",
            columnMenuFilter: "Filtrar",
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
        />
      </StyledContainer>
    </>
  );
};

export default UserDocumentStatus;
