import { Box, BoxProps, styled } from "@mui/material";

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  height: "100%",
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  padding: `${theme.spacing(2)} 0`,
  boxSizing: "border-box",
}));
