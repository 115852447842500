import { VisibilityOff, Visibility, ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import {
  Formik,
  Field,
  FieldInputProps,
  FieldMetaProps,
  FormikProps,
} from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useRef, useState } from "react";
import { Form, useNavigate } from "react-router-dom";

import { useCreateUserMutation } from "@/service";
import { theme } from "@/theme";

import { validationSchema } from "./SignUpForm.schema";
import { useStyles } from "./SignUpForm.styles";
import { ISignUpFormProps } from "./SignUpForm.types";

const SignUpForm: React.FC = () => {
  const styles = useStyles();
  const navigation = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const formikRef = useRef<FormikProps<ISignUpFormProps>>(null);
  const [createUser, { isLoading, isSuccess }] = useCreateUserMutation();

  const handleClickShowPassword = () => setShowPassword(prev => !prev);

  useEffect(() => {
    if (isSuccess) {
      formikRef.current?.setSubmitting(false);
      navigation("/session/login");
    } else {
      formikRef.current?.setSubmitting(false);
    }
  }, [isSuccess]);

  return (
    <Box className={styles.container}>
      <Formik
        data-testid='signup-form-component'
        innerRef={formikRef}
        initialValues={{
          rfc: "",
          fullName: "",
          fatherLastName: "",
          motherLastName: "",
          phoneNumber: "",
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          createUser({
            password: values.password,
            phone: values.phoneNumber,
            email: values.email,
            rfc: values.rfc,
            name: values.fullName,
            apPaterno: values.fatherLastName,
            apMaterno: values.motherLastName,
          });
        }}
      >
        {({ submitForm }) => (
          <Form className={styles.formInputsContainer}>
            <Field
              component={TextField}
              name='fullName'
              type='text'
              label='Nombres'
              fullWidth
            />
            <Field
              component={TextField}
              name='fatherLastName'
              type='text'
              label='Apellido paterno'
              fullWidth
            />
            <Field
              component={TextField}
              name='motherLastName'
              type='text'
              label='Apellido materno'
              fullWidth
            />
            <Field
              component={TextField}
              name='rfc'
              type='text'
              label='RFC'
              fullWidth
            />
            <Field
              component={TextField}
              name='phoneNumber'
              type='text'
              label='Teléfono de contacto'
              fullWidth
            />
            <Field
              component={TextField}
              name='email'
              type='text'
              label='Correo electrónico'
              fullWidth
            />
            <Field name='password'>
              {({
                field,
                meta,
              }: {
                field: FieldInputProps<unknown>;
                meta: FieldMetaProps<unknown>;
              }) => (
                <FormControl sx={{ width: "100%" }} variant='outlined'>
                  <InputLabel htmlFor='outlined-adornment-password'>
                    Contraseña
                  </InputLabel>
                  <OutlinedInput
                    {...field}
                    id='outlined-adornment-password'
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {meta.touched && meta.error ? (
                    <Typography variant='body2' color='tomato'>
                      {meta.error}
                    </Typography>
                  ) : null}
                </FormControl>
              )}
            </Field>
            <Button
              variant='contained'
              fullWidth
              size='large'
              disabled={isLoading}
              onClick={submitForm}
              sx={{ mt: theme.spacing(2), mb: theme.spacing(-1) }}
            >
              <Typography
                variant='body1'
                align='center'
                fontWeight='bold'
                textTransform='capitalize'
              >
                Continuar
              </Typography>
            </Button>
            <Button
              variant='text'
              fullWidth
              size='large'
              disabled={isLoading}
              startIcon={<ArrowBack />}
              onClick={() => navigation(-1)}
            >
              Atras
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SignUpForm;
