import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import DocumentsOverview from "@/components/DocumentsOverview";
import {
  Contract,
  EDocumentPaymentStatus,
  EDocumentStatus,
} from "@/components/UserDocumentStatus/UserDocumentStatus.types";
import { useLoader } from "@/hooks";
import { useLazyGetAllDocumentsQuery } from "@/service";

import { IDocumentsStatusDataGridProps } from "./DocumentsStatusDataGrid.types";

const DocumentsStatusDataGrid: React.FC<IDocumentsStatusDataGridProps> = ({
  criteria,
}) => {
  const { setLoader } = useLoader();
  const [filteredData, setFilteredData] = useState<{
    data: { data: { contracts: Contract[] } };
  } | null>(null);
  const [getAllDocuments, { data, isLoading, isSuccess }] =
    useLazyGetAllDocumentsQuery();

  useEffect(() => {
    getAllDocuments();
  }, []);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess && data) {
      const final = data.data.contracts.filter((doc: Contract) => {
        switch (criteria) {
          case "payments":
            return (
              doc.status === Object.keys(EDocumentStatus)[0] ||
              doc.status === Object.keys(EDocumentStatus)[2]
            );
          case "signatures":
            return (
              doc.status === Object.keys(EDocumentStatus)[3] &&
              doc.paymentStatus === Object.keys(EDocumentPaymentStatus)[1]
            );
          case "delivery":
            return (
              doc.status === Object.keys(EDocumentStatus)[1] &&
              doc.paymentStatus === Object.keys(EDocumentPaymentStatus)[1]
            );
          case "all":
            return doc;
        }
      });

      setFilteredData({ ...data, data: { contracts: [...final] } });
    }
  }, [data, isSuccess, criteria]);

  return (
    <Grid
      container
      direction='column'
      gap={2}
      data-testid='documents-status-data-grid-component'
    >
      <Grid item>
        {isSuccess && <DocumentsOverview documents={data.data.contracts} />}
      </Grid>
      <Grid item>
        <Outlet context={filteredData} />
      </Grid>
    </Grid>
  );
};

export default DocumentsStatusDataGrid;
