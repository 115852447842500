import { CloudDownloadOutlined, Refresh, Tune } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { GridToolbarContainer, useGridApiContext } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useLoader } from "@/hooks";
import { useLazyGetAllDocumentsQuery } from "@/service";

const UserDocumentStatusToolbar: React.FC = () => {
  const apiRef = useGridApiContext();
  const navigation = useNavigate();
  const [getAllDocuments, { isLoading }] = useLazyGetAllDocumentsQuery();
  const { setLoader } = useLoader();
  const handleDownload = () => apiRef.current.exportDataAsCsv();
  const handleFilter = () => apiRef.current.showFilterPanel();
  const handleCreateDocument = () =>
    navigation("/dashboard/v1/create-document");

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  return (
    <GridToolbarContainer>
      <Grid item container direction='row' mb={1}>
        <Grid item xs={6}>
          <Typography variant='body1' fontWeight='bold'>
            Autorización de firmas pendientes de revisión
          </Typography>
          <Typography variant='body2'>
            Aquí podrás visualizar todos los documentos que tienes creados
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={6}
          gap={2}
          direction='row'
          justifyContent='flex-end'
        >
          <Grid item>
            <Button
              variant='text'
              size='small'
              onClick={handleFilter}
              color='atmosphere'
              startIcon={<Tune />}
            >
              Filtrar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='text'
              size='small'
              onClick={handleDownload}
              color='atmosphere'
              startIcon={<CloudDownloadOutlined />}
            >
              Descargar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='text'
              size='small'
              color='atmosphere'
              onClick={() => getAllDocuments()}
              startIcon={<Refresh />}
            >
              Actualizar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              size='small'
              onClick={handleCreateDocument}
            >
              Iniciar proceso
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default UserDocumentStatusToolbar;
