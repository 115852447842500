import { CloudUpload } from "@mui/icons-material";
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import { ReactComponent as PaperArrowIcon } from "@/assets/icons/paper-arrow-icon.svg";
import { ReactComponent as ParticipantsIcon } from "@/assets/icons/participants-icon.svg";
import DocumentOption from "@/components/DocumentOption";
import FileLoadInput from "@/components/FileLoadInput";
import useDocument from "@/hooks/useDocument";
import { EDocumentTypes } from "@/state/Document/slice.types";
import { theme } from "@/theme";

import {
  StyledButtonContainer,
  StyledContainer,
  StyledContentContainer,
  StyledFooterContainer,
  StyledOptionsContainer,
} from "./DocumentCreation.styles";

const DocumentCreation: React.FC = () => {
  // eslint-disable-next-line
  const { createNewDocumentType } = useDocument();
  

  const handleUploadFileDocumentType = () => {
    createNewDocumentType(EDocumentTypes.RENTING);
  };

  return (
    <StyledContainer data-tesid='automatic-procedures-component'>
      <StyledContentContainer>
        <Box>
          <Typography variant='h4' align='center' color='primary'>
            Bienvenido <br /> a firma electrónica
          </Typography>
          <Typography variant='h6' align='center' color='primary'>
            Genera documentos listos para firmar
          </Typography>
        </Box>
        <StyledOptionsContainer>
          <DocumentOption
            step={1}
            id={EDocumentTypes.BUYING_PROMISE}
            icon={<PaperArrowIcon height={100} />}
            text='Sube los documentos en un solo archivo'
          />

          <DocumentOption
            step={2}
            id={EDocumentTypes.NONE}
            icon={<ParticipantsIcon height={100} />}
            text='Agrega participantes'
          />

        </StyledOptionsContainer>
        <Box sx={{ display: 'none' }}>
          <Typography variant='body1' align='center' fontWeight='bold'>
            O adjunta tu propio documento
          </Typography>
        </Box>
        <StyledButtonContainer>
          <Button
            variant='contained'
            size='large'
            startIcon={<CloudUpload />}
            component='label'
            onClick={handleUploadFileDocumentType}
            fullWidth
          >
            <Typography
              variant='body1'
              align='center'
              fontWeight='bold'
              textTransform='capitalize'
            >
              Subir archivo
            </Typography>
            <FileLoadInput />
          </Button>
        </StyledButtonContainer>
        <StyledFooterContainer>
          <Typography variant='body1' fontWeight='bold' color='primary'>
            Gracias por usar nuestros servicios
          </Typography>
          <Typography
            variant='body2'
            fontSize={theme.spacing(1.6)}
            fontWeight='bold'
          >
            Para más información, comunícate con nuestro equipo de soporte al cliente:
          </Typography>

          <div className="icons">
            <div
              className="icon-contact"
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=5215639550122",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              <WhatsAppIcon />
            </div>
            <div
              className="icon-contact"
              onClick={() => (window.location.href = "tel:+5215639550122")}
            >
              <PhoneIcon />
            </div>
            <div
              className="icon-contact"
              onClick={() =>
              (window.location.href =
                "mailto:diego@firmavirtual.legal")
              }
            >
              <MailOutlinedIcon />
            </div>
          </div>

        </StyledFooterContainer>
      </StyledContentContainer>
      <Outlet />
    </StyledContainer>
  );
};

export default React.memo(DocumentCreation, () => {
  return false;
});
