import React from "react";
import { Outlet } from "react-router-dom";

import Layout from "@/components/Layout";

const Dashboard: React.FC = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default Dashboard;
