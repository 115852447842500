import { EDocumentSignerRoles } from "@/state/Document/slice.types";

export interface IProgressStatusProps {
  progress: string;
  status: "progress" | "pending" | "complete";
}

export interface IListItemHeaderProps {
  itemId: string | number;
  primaryText: string;
  secondaryText?: string;
}

export interface IDocumentDetailDataProps {
  contract: Contract;
  signers: Signer[];
  files: File[];
}

export interface File {
  file_name: string;
}

export interface Contract {
  contractID: string;
  ownerType: string;
  contractTypeFeeID: ContractTypeFeeID;
  status: string;
  paymentStatus: string;
  contractOwner: string;
  numberID: string;
  signedCount: number;
  createdAt: string;
}

export interface ContractTypeFeeID {
  id: string;
  contract_type_id: string;
  modality: string;
  sign_count: string;
  amount: string;
  amount_iva: string;
  iva: string;
  total: string;
  status: string;
  name: string;
}

export interface Signer {
  id: string;
  signerID: string;
  rfc: string;
  name: string;
  apPaterno: string;
  apMaterno: string;
  email: string;
  rol: EDocumentSignerRoles;
  contractID: string;
  signer_type: string;
  portion: string;
  payment: string;
  ivapay: string;
  totalpay: string;
  payout: string;
  sign_status: string;
  page_sign: string;
  posX: string;
  posY: string;
  verification: ISignerVerification[];
  files: IUploadedFiles[];
  link_payment: { link_pay: string }[];
}

export interface ISignerVerification {
  id: string;
  contract_id: string;
  signer_id: string;
  send_mail: EOKStatus;
  verified_email: EOKStatus;
  verification_code: string;
  upload_identity_photo: EOKStatus;
  upload_identity_biometri: EOKStatus;
  upload_identity_video: EOKStatus;
  sessionID: string;
  idScan: string;
  isCompletelyDone: EOKStatus;
  status: ESignerVerification;
}

export enum EOKStatus {
  NOT_OK = "0",
  OK = "1",
}

export enum ESignerVerification {
  EMAIL = "Email",
  FINISH = "Finish",
}

export interface IUploadedFiles {
  fileName: string;
}

export interface IProgressStatus {
  progress: "payment" | "signature" | "handed";
}
