import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      padding: theme.spacing(2),
    },
    formInputsContainer: {
      gap: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
    },
    buttonContainer: {
      marginTop: theme.spacing(2),
    },
  }),
);
