import { ArrowBack } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { Formik, Field } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect } from "react";
import { Form, useNavigate } from "react-router-dom";

import { useLoader, useMessager } from "@/hooks";
import { useResetPasswordMutation } from "@/service";
import { theme } from "@/theme";

import { validationSchema } from "./ResetPassword.schema";

const ResetPassword: React.FC = () => {
  const navigation = useNavigate();
  const { setLoader } = useLoader();
  const { setMessage } = useMessager();
  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();

  useEffect(() => {
    setLoader(isLoading);
    if (isSuccess) {
      setMessage({
        title: "Revise su correo electrónico",
        body: "Hemos enviado un correo electrónico con las indicaciones para restablecer su contraseña",
        open: true,
        type: "modal",
        buttonCloseTitle: "Entendido",
        onClose: () => navigation(-1),
      });
    }
  }, [isLoading, isSuccess]);

  return (
    <Stack spacing={4}>
      <Typography variant='h6' textAlign='center'>
        Ingrese el correo electrónico asociado a la cuenta
      </Typography>
      <Formik
        data-testid='reset-password-page'
        validationSchema={validationSchema}
        initialValues={{ email: "" }}
        onSubmit={(values, { setSubmitting }) => {
          resetPassword(values.email);
          setSubmitting(false);
        }}
      >
        {({ submitForm }) => (
          <Form>
            <Stack spacing={4}>
              <Field
                component={TextField}
                name='email'
                type='text'
                label='Correo electrónico'
                fullWidth
              />
              <Button
                variant='contained'
                fullWidth
                size='large'
                onClick={submitForm}
                disabled={isLoading}
                sx={{ mt: theme.spacing(2), mb: theme.spacing(-1) }}
              >
                <Typography
                  variant='body1'
                  align='center'
                  fontWeight='bold'
                  textTransform='capitalize'
                >
                  Recuperar contraseña
                </Typography>
              </Button>
              <Button
                variant='text'
                fullWidth
                size='large'
                disabled={isLoading}
                startIcon={<ArrowBack />}
                onClick={() => navigation(-1)}
              >
                Atras
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export default ResetPassword;
