import { Typography } from "@mui/material";
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import React from "react";

import useDocument from "@/hooks/useDocument";
import {
  EDocumentCreationSteps,
  EDocumentTypes,
} from "@/state/Document/slice.types";

import {
  StyledContainer,
  StyledIconContainer,
  StyledInfoContainer,
} from "./DocumentOption.styles";
import { IAutoOptionProps } from "./DocumentOption.types";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: -22,
    top: 13,
    width: 38,
    height: 37.683,
    fontSize: 16,
    borderRadius: 7,
    backgroundColor: theme.palette.primary.light
  },
}));

const DocumentOption: React.FC<IAutoOptionProps> = ({
  step,
  icon,
  text,
  id,
  selected,
  onClick,
}) => {
  const {
    documentSt,
    createNewDocumentType,
    setDocumentStep,
    clearDocumentSt,
  } = useDocument();

  const handleClick = ({ type }: { type: EDocumentTypes }) => {
    if (documentSt.documentType !== type) {
      createNewDocumentType(type);
      setDocumentStep(EDocumentCreationSteps.SET_TYPE);
    } else {
      clearDocumentSt();
    }
    onClick && onClick(type);
  };

  return (
    <StyledBadge badgeContent={step} color="secondary">
      <StyledContainer
        data-testid='document-option-component'
        onClick={() => handleClick({ type: id })}
      >
        <StyledIconContainer isselected={Boolean(selected)}>
          {icon}
        </StyledIconContainer>
        <StyledInfoContainer>
          <Typography
            variant='body2'
            color='primary'
            align='center'
            fontWeight='bold'
            fontSize={16}
          >
            {text}
          </Typography>
        </StyledInfoContainer>
      </StyledContainer>
    </StyledBadge>
  );
};

export default DocumentOption;
