import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  alpha,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useMessager } from "@/hooks";
import { theme } from "@/theme";

const GlobalModal: React.FC = () => {
  const { messageSt, clearMessage } = useMessager();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(messageSt.open);
  }, [messageSt.open]);

  const handleClose = () => {
    setOpen((prev: boolean) => !prev);
    clearMessage();
    messageSt?.onClose && messageSt?.onClose();
  };

  const handleExtraButton = () => {
    clearMessage();
    setOpen((prev: boolean) => !prev);
    messageSt.handleExtraButtonClick && messageSt.handleExtraButtonClick();
  };

  return (
    <Dialog
      open={open}
      sx={{
        backgroundColor: alpha(theme.palette.common.white, 0.6),
        backdropFilter: "blur(2px)",
      }}
    >
      <DialogTitle>{messageSt.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='message-description'>
          {messageSt.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {messageSt.extraButton && (
          <Button onClick={handleExtraButton}>
            {messageSt.extraButtonTitle}
          </Button>
        )}
        <Button onClick={handleClose}>{messageSt.buttonCloseTitle}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GlobalModal;
