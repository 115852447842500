import { Box, BoxProps, styled } from "@mui/material";

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  boxShadow: theme.shadows[24],
}));
