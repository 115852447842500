import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import jwt from "jwt-decode";

import { resolveSessionQuery } from "@/helpers";
import {
  EServiceStatus,
  ICreateDocumentProps,
  ISessionsLoginProps,
  ISessionsCreateUserProps,
  IValidationIdProps,
  IValidationProps,
} from "@/service/service.types";
import { setDocumentsCreated, setContractTypes } from "@/state/Document/slice";
import { setMessage } from "@/state/Messager/slice";
import {
  setNavigationHistory,
  setShouldResetPassword,
} from "@/state/Navigation/slice";
import { setUserSession, setError } from "@/state/Session/slice";
import { ISessionState } from "@/state/Session/slice.types";
import { setValidationStatus } from "@/state/SignerValidation/slice";
import { store } from "@/store";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiSlice: any = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/`,
  }),
  tagTypes: ["API"],
  endpoints: builder => ({
    checkEmailAccount: builder.mutation({
      query: (body: { email: string }) => ({
        url: "verifyEmail",
        method: "POST",
        body: { email: body.email },
      }),
    }),
    createUser: builder.mutation({
      query: (body: ISessionsCreateUserProps) => ({
        url: "register",
        method: "POST",
        body: { ...body, username: body.email },
      }),
      onQueryStarted: (_arg, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(response => response)
          .catch(error => {
            console.error(`🚨 service error for /api/login: ${error}`);
            dispatch(
              setMessage({
                title: "¡Ups, algo ha pasado!",
                body: "Al parecer ha ocurrido un error, por favor verifica tus datos o intentalo mas tarde.",
                buttonCloseTitle: "Entendido",
                open: true,
                type: "modal",
              }),
            );
            dispatch(
              setError({
                message: error.message,
                code: error?.code as string,
              }),
            );
          });
      },
    }),
    updateUser: builder.mutation({
      query: (body: ISessionsCreateUserProps) => ({
        url: `users/${store.getState().session.userID}`,
        method: "PUT",
        body: { ...body, username: body.email },
        headers: {
          authorization: `Bearer ${store.getState().session.token}`,
        },
      }),
      onQueryStarted: (_arg, { queryFulfilled }) => {
        resolveSessionQuery(
          queryFulfilled
            .then(response => {
              return response;
            })
            .catch(msg => {
              return msg;
            }),
        );
      },
    }),
    loginUser: builder.mutation({
      query: (body: ISessionsLoginProps) => ({
        url: "login",
        method: "POST",
        body,
      }),
      onQueryStarted: (_arg, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(response => {
            if (response.data?.status === EServiceStatus.SUCCESS) {
              const data: { user: ISessionState } = jwt(
                response.data.data[0].token,
              );
              dispatch(
                setUserSession({
                  ...data.user,
                  token: response.data.data[0].token,
                }),
              );
            }
          })
          .catch(error => {
            console.error(`🚨 service error for /api/login:`);
            console.error(error);
            dispatch(
              setMessage({
                title: "¡Ups! Algo ha pasado",
                body: "Al parecer tenemos problemas con nuestros servicios o el usuario y contraseña proporcionados son incorrectos, le pedimos disculpas, por favor verifique sus credenciales o inténtelo en unos minutos.",
                open: true,
                buttonCloseTitle: "Entendido",
                type: "modal",
              }),
            );
            dispatch(
              setError({
                message: error.message,
                code: error?.code as string,
              }),
            );
          });
      },
    }),
    validateSigner: builder.query({
      query: (body: IValidationProps) => ({
        url: `verifyCodeBio/${body.contract_id}/${body.signerr_id}/${body.verification_code}`,
      }),
      onQueryStarted: (_arg, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(response => {
            if (response.data?.status === EServiceStatus.SUCCESS) {
              dispatch(setValidationStatus({ isValid: true }));
            }
          })
          .catch(error => {
            console.error(`🚨 service error for /api/verifyCodeBio: ${error}`);
            dispatch(
              setMessage({
                title: "¡Ups! Algo ha pasado",
                body: "Al parecer tenemos problemas con nuestros servicios, te pedimos disculpas, por favor intentalo nuevamente en unos minutos",
                open: true,
                buttonCloseTitle: "Entendido",
                type: "modal",
              }),
            );
            dispatch(setError({ message: error.message, code: error?.code }));
          });
      },
    }),
    validateSignerId: builder.mutation({
      query: (body: IValidationIdProps) => ({
        url: "uploadFiles",
        method: "POST",
        body: {
          file: body.file,
          imgIdentityFront: body.imgIdentityFront,
          imgIdentityBack: body.imgIdentityBack,
          contractID: body.contractID,
          signerID: body.signerID,
        },
        headers: {
          authorization: `Bearer ${store.getState().session.token}`,
        },
      }),
      onQueryStarted: (_arg, { dispatch, queryFulfilled }) => {
        resolveSessionQuery(
          queryFulfilled
            .then(response => response)
            .catch(msg => {
              dispatch(setError({ message: msg.message, code: msg?.code }));
              return msg;
            }),
        );
      },
    }),
    createDocument: builder.mutation({
      query: (body: ICreateDocumentProps) => ({
        url: "contract",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${store.getState().session.token}`,
        },
      }),
      onQueryStarted: (_arg, { queryFulfilled }) => {
        resolveSessionQuery(
          queryFulfilled
            .then(response => response)
            .catch(msg => {
              return msg;
            }),
        );
      },
    }),
    deleteDocument: builder.mutation({
      query: (contractId: string) => ({
        url: `contract/${contractId}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${store.getState().session.token}`,
        },
      }),
      onQueryStarted: (_arg, { queryFulfilled }) => {
        resolveSessionQuery(
          queryFulfilled
            .then(response => response)
            .catch(msg => {
              return msg;
            }),
        );
      },
    }),
    getAllDocuments: builder.query({
      query: () => ({
        url: "contract/getAll",
        method: "GET",
        headers: {
          authorization: `Bearer ${store.getState().session.token}`,
        },
      }),
      onQueryStarted: (_arg, { dispatch, queryFulfilled }) => {
        resolveSessionQuery(
          queryFulfilled
            .then(response => {
              dispatch(setDocumentsCreated(response.data.data.contracts));
            })
            .catch(msg => {
              return msg;
            }),
        );
      },
    }),
    getAllContractTypes: builder.query({
      query: () => ({
        url: "contract-type/getAll",
        method: "GET",
        headers: {
          authorization: `Bearer ${store.getState().session.token}`,
        },
      }),
      onQueryStarted: (_arg, { dispatch, queryFulfilled }) => {
        resolveSessionQuery(
          queryFulfilled
            .then(response => {
              dispatch(setContractTypes(response.data.data.contractTypes));
            })
            .catch(msg => {
              return msg;
            }),
        );
      },
    }),
    getOneDocument: builder.query({
      query: (contractId: string) => ({
        url: `/contractid/${contractId}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${store.getState().session.token}`,
        },
      }),
      providesTags: (result, error, id) => [{ type: "API", id }],
      onQueryStarted: (_arg, { queryFulfilled }) => {
        resolveSessionQuery(
          queryFulfilled
            .then(response => response)
            .catch(msg => {
              return msg;
            }),
        );
      },
    }),
    downloadDocument: builder.query({
      query: (contractId: string) => ({
        url: `/download/${contractId}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${store.getState().session.token}`,
        },
      }),
      providesTags: (result, error, id) => [{ type: "API", id }],
      onQueryStarted: (_arg, { queryFulfilled }) => {
        resolveSessionQuery(
          queryFulfilled
            .then(response => response)
            .catch(msg => {
              return msg;
            }),
        );
      },
    }),
    downloadVideo: builder.query({
      query: ({
        contractId,
        signerId,
      }: {
        contractId: string;
        signerId: string;
      }) => ({
        url: `/download/${contractId}/${signerId}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${store.getState().session.token}`,
        },
      }),
      onQueryStarted: (_arg, { queryFulfilled }) => {
        resolveSessionQuery(
          queryFulfilled
            .then(response => response)
            .catch(msg => {
              return msg;
            }),
        );
      },
    }),
    forwardEmail: builder.query({
      query: ({
        contractId,
        signerId,
      }: {
        contractId: string;
        signerId: string;
      }) => ({
        url: `/forwardMail/${contractId}/${signerId}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${store.getState().session.token}`,
        },
      }),
      onQueryStarted: (_arg, { queryFulfilled }) => {
        resolveSessionQuery(
          queryFulfilled
            .then(response => response)
            .catch(msg => {
              return msg;
            }),
        );
      },
    }),
    resetPassword: builder.mutation({
      query: (email: string) => ({
        url: `/resetPassword`,
        method: "POST",
        body: { email },
      }),
      onQueryStarted: (_arg, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(response => response)
          .catch(error => {
            console.error(`🚨 service error for /api/resetPassword:`);
            console.error(error);
            dispatch(
              setMessage({
                title: "¡Ups! Algo ha pasado",
                body: "Al parecer tenemos problemas con nuestros servicios, te pedimos disculpas, por favor intentalo nuevamente en unos minutos",
                open: true,
                buttonCloseTitle: "Entendido",
                type: "modal",
              }),
            );
          });
      },
    }),
    verifyResetPassword: builder.mutation({
      query: (email: string) => ({
        url: `/verifyResetPassword`,
        method: "POST",
        body: { email },
      }),
      onQueryStarted: (_arg, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(response => {
            dispatch(setShouldResetPassword(response.data.is_reset));
            !response.data.is_reset &&
              dispatch(setNavigationHistory({ activeSession: true }));
          })
          .catch(error => {
            console.error(`🚨 service error for /api/verifyResetPassword:`);
            console.error(error);
            dispatch(
              setMessage({
                title: "¡Ups! Algo ha pasado",
                body: "Al parecer tenemos problemas con nuestros servicios, te pedimos disculpas, por favor intentalo nuevamente en unos minutos",
                open: true,
                buttonCloseTitle: "Entendido",
                type: "modal",
              }),
            );
          });
      },
    }),
    changePassword: builder.mutation({
      query: ({
        password,
        password_confirm,
      }: {
        password: string;
        password_confirm: string;
      }) => ({
        url: `/users/changePassword/${store.getState().session.userID}`,
        method: "PUT",
        body: { password, password_confirm },
        headers: {
          authorization: `Bearer ${store.getState().session.token}`,
        },
      }),
      onQueryStarted: (_arg, { queryFulfilled }) => {
        resolveSessionQuery(
          queryFulfilled
            .then(response => response)
            .catch(msg => {
              return msg;
            }),
        );
      },
    }),
  }),
});

export const {
  useCheckEmailAccountMutation,
  useCreateUserMutation,
  useLoginUserMutation,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useValidateSignerQuery,
  useValidateSignerIdMutation,
  useLazyValidateSignerQuery,
  useLazyGetAllDocumentsQuery,
  useLazyGetAllContractTypesQuery,
  useLazyGetOneDocumentQuery,
  useLazyDownloadDocumentQuery,
  useLazyDownloadVideoQuery,
  useLazyForwardEmailQuery,
  useResetPasswordMutation,
  useVerifyResetPasswordMutation,
  useChangePasswordMutation,
  useUpdateUserMutation,
} = apiSlice;
