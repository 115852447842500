import React from "react";

import NavigationMenu from "@/components/NavigationMenu";

import { StyledContainer } from "./SideMenu.styles";

const SideMenu: React.FC = () => {
  return (
    <StyledContainer data-testid='side-menu-component'>
      <NavigationMenu />
    </StyledContainer>
  );
};

export default SideMenu;
