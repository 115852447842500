import React from "react";
import { useNavigate } from "react-router-dom";

import useDocument from "@/hooks/useDocument";

import { HiddenInput } from "./FileLoadInput.styles";

const FileLoadInput: React.FC = () => {
  const { setDocumentAsBase64 } = useDocument();
  const navigation = useNavigate();

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      if (file.type !== "application/pdf") {
        console.error("The uploaded file is not a PDF.");
        return;
      }

      const base64File = await fileToArrayBufferToBase64(file);
      if (base64File) {
        setDocumentAsBase64(base64File as string);
        navigation("/dashboard/v1/upload-document/signers");
      }
    } else {
      console.error("No file selected");
    }
  };

  const fileToArrayBufferToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = event => {
        if (event.target?.result) {
          const arrayBuffer: ArrayBuffer = event.target.result as ArrayBuffer;
          const uint8Array = new Uint8Array(arrayBuffer);

          const chunks: string[] = [];
          const chunkSize = 0xffff; // Use chunks of 65,535 bytes

          for (let i = 0; i < uint8Array.length; i += chunkSize) {
            chunks.push(
              String.fromCharCode.apply(
                null,
                Array.from(uint8Array.subarray(i, i + chunkSize)),
              ),
            );
          }

          const base64String =
            `data:${file.type};base64,` + btoa(chunks.join(""));

          resolve(base64String);
        }
      };

      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };

      reader.readAsArrayBuffer(file);
    });
  };

  return (
    <HiddenInput
      type='file'
      data-testid='file-load-input-component'
      onChange={handleFileUpload}
    />
  );
};

export default FileLoadInput;
