import { AddBox, Circle } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  IconProps,
  ListItem,
  ListItemProps,
  lighten,
  styled,
} from "@mui/material";

interface StyledRemoveItemProps extends ListItemProps {
  remove: boolean;
}

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  padding: `${theme.spacing(8)} 0`,
  boxSizing: "border-box",
  borderRadius: theme.shape.borderRadius,
  gap: theme.spacing(7),
  flex: 1,
}));

export const StyledCircle = styled(Circle)<IconProps>(({ theme }) => ({
  color: theme.palette.primary.light,
}));

export const StyledAddBox = styled(AddBox)<IconProps>(({ theme }) => ({
  color: theme.palette.primary.light,
}));

export const StyledRemoveItem = styled(ListItem)<StyledRemoveItemProps>(
  ({ theme, remove }) => ({
    backgroundColor: remove
      ? lighten(theme.palette.secondary.light, 0.4)
      : "transparent",
  }),
);
