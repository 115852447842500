import * as yup from "yup";

const rfcRegex = /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/i;

export const validationSchema = yup.object({
  fullName: yup.string().required("Introduce tu nombre").min(2).max(256).trim(),
  fatherLastname: yup
    .string()
    .required("Introduce tu apellido paterno")
    .min(2)
    .max(256)
    .trim(),
  motherLastname: yup
    .string()
    .required("Introduce tu apellido materno")
    .min(2)
    .max(256)
    .trim(),
  role: yup.number().required("Selecciona un Rol").min(1).max(3),
  email: yup
    .string()
    .email()
    .required("Introduce tu correo electrónico")
    .trim(),
  RFC: yup
    .string()
    .matches(rfcRegex, "RFC inválido")
    .required("Este campor es requerido"),
  portion: yup
    .number()
    .oneOf([0, 50, 100])
    .required("Selecciona un porcentaje a pagar"),
});
