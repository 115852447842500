import { useDispatch, useSelector } from "react-redux";

import { navigationSelector } from "@/state/Navigation/selectors";
import { setShouldResetPassword as setShouldResetPasswordAction } from "@/state/Navigation/slice";

const useNavigation = () => {
  const dispatch = useDispatch();
  const navigationSt = useSelector(navigationSelector);

  const setShouldResetPassword = (reset: boolean) =>
    dispatch(setShouldResetPasswordAction(reset));

  return {
    navigationSt,
    setShouldResetPassword,
  };
};

export default useNavigation;
