import { Box, BoxProps, styled } from "@mui/material";

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(8),
  boxSizing: "border-box",
  borderRadius: theme.shape.borderRadius,
  gap: theme.spacing(7),
  flex: 1,
}));
