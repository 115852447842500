import {
  ChevronRight,
  Description,
  Download,
  Person,
  Schedule,
  Visibility,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import React from "react";

import { useMessager, useSession } from "@/hooks";
import { useLazyDownloadDocumentQuery } from "@/service";
import { theme } from "@/theme";

import { useStyles } from "./DocumentDetailData.styles";
import {
  IDocumentDetailDataProps,
  IListItemHeaderProps,
  IProgressStatusProps,
  IProgressStatus,
} from "./DocumentDetailData.types";
import PDFViewer from "../PDFViewer";

const ProgressStatus: React.FC<IProgressStatusProps> = ({
  progress,
  status,
}) => {
  const styles = useStyles();
  const transformStatus = () => {
    if (status) {
      switch (status) {
        case "progress":
          return "En progreso";
        case "pending":
          return "Por realizar";
        case "complete":
          return "Completado";
      }
    }
  };

  const transformStatusColor = () => {
    if (status) {
      switch (status) {
        case "progress":
          return theme.palette.autumn?.main;
        case "pending":
          return theme.palette.secondary.main;
        case "complete":
          return theme.palette.success.light;
      }
    }
  };

  const transformStatusBgColor = () => {
    if (status) {
      switch (status) {
        case "progress":
          return theme.palette.autumn?.main;
        case "pending":
          return theme.palette.grey[400];
        case "complete":
          return theme.palette.success.light;
      }
    }
  };

  return (
    <Stack direction='row' spacing={1}>
      <Box
        className={styles.crumIconContainer}
        sx={{
          backgroundColor: alpha(
            transformStatusBgColor() ?? theme.palette.grey[400],
            0.4,
          ),
        }}
      >
        <Schedule width={20} sx={{ fill: transformStatusColor() }} />
      </Box>
      <Box>
        <Typography
          variant='body2'
          display='block'
          fontSize={theme.typography.caption.fontSize}
          fontWeight='bold'
        >
          {progress}
        </Typography>
        <Typography
          variant='body2'
          fontSize={theme.typography.caption.fontSize}
          color={transformStatusColor()}
        >
          {transformStatus()}
        </Typography>
      </Box>
    </Stack>
  );
};

const ListHeader: React.FC<IListItemHeaderProps> = ({
  itemId,
  primaryText,
  secondaryText,
}) => {
  return (
    <Grid item>
      <List>
        <ListItem>
          <ListItemIcon>
            <Box
              height={30}
              width={30}
              bgcolor={theme.palette.primary.light}
              borderRadius={1}
              justifyContent='center'
              alignItems='center'
              display='flex'
            >
              <Typography variant='h6' color='white'>
                {itemId}
              </Typography>
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={primaryText}
            primaryTypographyProps={{ fontWeight: "bold" }}
            secondary={secondaryText}
          />
        </ListItem>
      </List>
    </Grid>
  );
};

const DocumentDetailData: React.FC<IDocumentDetailDataProps> = ({
  files,
  contract,
  signers,
}) => {
  const styles = useStyles();
  const { sessionUserSt } = useSession();
  const { setMessage } = useMessager();
  const [downloadDocument] = useLazyDownloadDocumentQuery();

  const validateStatus = ({
    progress,
  }: IProgressStatus): IProgressStatusProps["status"] => {
    let status;

    if (progress === "payment") {
      if (
        (contract.status === "SIGNED_PENDING" &&
          contract.paymentStatus === "PAIDOUT") ||
        contract.status === "SIGNED"
      ) {
        status = "complete";
      } else {
        status = "pending";
      }
    }

    if (progress === "signature") {
      if (
        contract.status === "SIGNED" &&
        contract.paymentStatus === "PAIDOUT"
      ) {
        status = "complete";
      } else {
        status = "pending";
      }
    }

    if (progress === "handed") {
      if (
        contract.status === "SIGNED" &&
        contract.paymentStatus === "PAIDOUT"
      ) {
        status = "complete";
      } else {
        status = "pending";
      }
    }

    return status;
  };

  const crums = [
    <ProgressStatus
      progress='Pago'
      status={validateStatus({
        progress: "payment",
      })}
    />,
    <ProgressStatus
      progress='Firma'
      status={validateStatus({
        progress: "signature",
      })}
    />,
    <ProgressStatus
      progress='Entrega'
      status={validateStatus({
        progress: "handed",
      })}
    />,
  ];

  const downloadPdf = (base64String: string, fileName = "documento.pdf") => {
    const pdfBytes = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  };

  const downloadDocumentPDF = async (id: string) => {
    const res = await downloadDocument(id);
    if (res.isSuccess) {
      downloadPdf(res.data.file);
    }
  };

  const viewDocumentAsPDF = async (id: string) => {
    const res = await downloadDocument(id);
    if (res.isSuccess) {
      if (res.data.file) {
        setMessage({
          title: "Contrato de arriendo",
          buttonCloseTitle: "Cerrar",
          type: "modal",
          open: true,
          body: (
            <PDFViewer
              base64={`data:application/pdf;base64,${res.data.file}`}
            />
          ),
        });
      }
    }
  };

  return (
    <>
      <Stack
        spacing={2}
        pb={theme.spacing(2)}
        justifyContent='center'
        alignItems='center'
      >
        <Breadcrumbs
          separator={<ChevronRight fontSize='medium' />}
          aria-label='breadcrumb'
        >
          {crums}
        </Breadcrumbs>
      </Stack>
      <Stack
        data-testid='document-detail-data-component'
        className={styles.container}
      >
        <Grid container direction='column'>
          <ListHeader
            itemId={1}
            primaryText='Datos personales del solicitante'
            secondaryText='Mis datos personales'
          />
          <Grid
            container
            direction='row'
            gap={theme.spacing(2)}
            px={theme.spacing(2)}
          >
            <Grid item>
              <Stack direction='row' alignItems='center'>
                <Avatar
                  alt='Datos del solicitante'
                  sx={{ backgroundColor: theme.palette.almostwhite?.dark }}
                >
                  <Person fontSize='medium' />
                </Avatar>
                <List>
                  <ListItem>
                    <ListItemText
                      primary='Nombre'
                      primaryTypographyProps={{
                        fontWeight: "bold",
                        fontSize: theme.typography.body2.fontSize,
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary='Correo electrónico'
                      primaryTypographyProps={{
                        fontWeight: "bold",
                        fontSize: theme.typography.body2.fontSize,
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary='Teléfono'
                      primaryTypographyProps={{
                        fontWeight: "bold",
                        fontSize: theme.typography.body2.fontSize,
                      }}
                    />
                  </ListItem>
                </List>
              </Stack>
            </Grid>
            <Grid item>
              <List>
                <ListItem>
                  <ListItemText
                    primary={`${sessionUserSt.name} ${sessionUserSt.apPaterno}`}
                    primaryTypographyProps={{
                      color: "GrayText",
                      fontSize: theme.typography.body2.fontSize,
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={sessionUserSt.email}
                    primaryTypographyProps={{
                      color: "GrayText",
                      fontSize: theme.typography.body2.fontSize,
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={sessionUserSt.phone}
                    primaryTypographyProps={{
                      color: "GrayText",
                      fontSize: theme.typography.body2.fontSize,
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction='column'>
          <ListHeader
            itemId={2}
            primaryText='Datos del documento'
            secondaryText='Datos del documento a realizar'
          />
          <Grid
            container
            direction='row'
            gap={theme.spacing(2)}
            px={theme.spacing(2)}
          >
            <Grid item>
              <Stack direction='row' alignItems='center'>
                <List>
                  <ListItem>
                    <ListItemText
                      primary='ID del documento'
                      primaryTypographyProps={{
                        fontWeight: "bold",
                        fontSize: theme.typography.body2.fontSize,
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary='Participantes'
                      primaryTypographyProps={{
                        fontWeight: "bold",
                        fontSize: theme.typography.body2.fontSize,
                      }}
                    />
                  </ListItem>
                </List>
              </Stack>
            </Grid>
            <Grid item>
              <List>
                <ListItem>
                  <ListItemText
                    primary={contract.numberID}
                    primaryTypographyProps={{
                      color: "GrayText",
                      fontSize: theme.typography.body2.fontSize,
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={signers.length}
                    primaryTypographyProps={{
                      color: "GrayText",
                      fontSize: theme.typography.body2.fontSize,
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction='column'>
          <ListHeader itemId={3} primaryText='Documentos a firmar' />
          <Grid
            container
            direction='row'
            gap={theme.spacing(2)}
            px={theme.spacing(2)}
          >
            <Grid item>
              <List>
                {files.map((file, index) => (
                  <ListItem key={`${file.file_name}_${index}`}>
                    <Box pr={2}>
                      <Description
                        fontSize='small'
                        sx={{ fill: theme.palette.primary?.main }}
                      />
                    </Box>
                    <ListItemText
                      primary={file.file_name}
                      primaryTypographyProps={{
                        color: "GrayText",
                        fontSize: theme.typography.body2.fontSize,
                      }}
                    />
                    <Stack direction='row' pl={2}>
                      <IconButton
                        size='small'
                        onClick={() =>
                          viewDocumentAsPDF(file.file_name.split(".")[0])
                        }
                      >
                        <Visibility
                          fontSize='small'
                          sx={{ fill: theme.palette.primary?.main }}
                        />
                      </IconButton>
                      <IconButton
                        size='small'
                        onClick={() =>
                          downloadDocumentPDF(file.file_name.split(".")[0])
                        }
                      >
                        <Download
                          fontSize='small'
                          sx={{ fill: theme.palette.primary?.main }}
                        />
                      </IconButton>
                    </Stack>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction='column'>
          <ListHeader
            itemId={4}
            primaryText='Resumen del pedido'
            secondaryText='Total a pagar'
          />
          <Grid
            container
            direction='row'
            gap={theme.spacing(2)}
            px={theme.spacing(2)}
          >
            <Grid item xs={12}>
              <Paper square={false} className={styles.summaryContainer}>
                <List dense>
                  <ListItem
                    secondaryAction={
                      <ListItemText
                        primary={`$${contract.contractTypeFeeID.amount}`}
                      />
                    }
                  >
                    <ListItemText
                      primary={contract.contractTypeFeeID.name}
                      // primary={`DOCUMENTO`}
                      sx={{ textTransform: "capitalize" }}
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      <ListItemText
                        // primary={`${contract.contractTypeFeeID.amount_iva}`}
                        primary={`${(parseFloat(contract.contractTypeFeeID.iva) * 100)}%`}
                      />
                    }
                    divider
                  >
                    <ListItemText
                      primary='IVA'
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      <ListItemText
                        primary={`$${contract.contractTypeFeeID.total}`}
                      />
                    }
                  >
                    <ListItemText
                      primary='Total'
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default DocumentDetailData;
