import { ReactComponent as DocFileIcon } from "@/assets/icons/doc-file-icon.svg";
import { ReactComponent as HomeIcon } from "@/assets/icons/home-icon.svg";
import { ReactComponent as UserIcon } from "@/assets/icons/user-icon.svg";

import { INavigationMenuMap } from "./NavigationMenu.types";

export const menuItems: INavigationMenuMap[] = [
  {
    text: "Inicio",
    id: "home",
    icon: <HomeIcon color='primary' width={20} height={20} />,
    subMenu: [],
    link: "create-document",
  },
  {
    text: "Gestión de documentos",
    id: "manage-formalities",
    icon: <DocFileIcon color='primary' width={20} height={20} />,
    link: null,
    subMenu: [
      {
        text: "Crear documentos",
        id: "create-formalities",
        to: "create-document",
      },
      {
        text: "Gestión de pagos",
        id: "manage-payments",
        to: "review-documents-payment/status",
      },
      {
        text: "Gestión de firmas",
        id: "manage-signatures",
        to: "review-documents-signature/status",
      },
      {
        text: "Gestión de entregas",
        id: "manage-deliveries",
        to: "review-documents-delivery/status",
      },
      {
        text: "Gestión de documentos",
        id: "manage-documents",
        to: "review-documents/status",
      },
    ],
  },
  {
    text: "Gestión de perfil",
    id: "user-settings",
    icon: <UserIcon color='primary' width={20} height={20} />,
    link: null,
    subMenu: [],
  },
];
