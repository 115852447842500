import { Box, BoxProps, Grid, GridProps, styled } from "@mui/material";

import paperbg from "@/assets/img/welcome_paper.jpg";

export const StyledFormContainer = styled(Box)<BoxProps>(() => ({
  flex: 1,
}));

export const StyledShowroomContainer = styled(Grid)<GridProps>(() => ({
  backgroundImage: `url(${paperbg})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
}));
