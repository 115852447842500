import * as yup from "yup";

export const validationSchema = yup.object({
  email: yup
    .string()
    .email()
    .required("Por favor introduce tu correo electrónico"),
  password: yup
    .string()
    .min(7)
    .max(16)
    .required("Por favor introduce tu contraseña"),
});

export const passwordValidationSchema = yup.object({
  password: yup.string().min(8).max(16).required(),
});
