import { Person } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";

import { theme } from "@/theme";

import {
  StyledContainer,
  StyledInfoContainer,
  StyledPictureContainer,
} from "./AvatarContainer.styles";
import { IAvatarContainerProps } from "./AvatarContainer.types";

const AvatarContainer: React.FC<IAvatarContainerProps> = ({
  userName,
  userRole,
  userPicture,
}) => {
  return (
    <StyledContainer data-testid='avatar-container-component'>
      <StyledInfoContainer>
        <Typography variant='h6' lineHeight={1} fontSize={14}>
          {userName}
        </Typography>
        <Typography
          variant='body2'
          fontSize={14}
          color={theme.palette.grey[500]}
        >
          {userRole}
        </Typography>
      </StyledInfoContainer>
      <StyledPictureContainer>
        {userPicture ?? <Person color='primary' />}
      </StyledPictureContainer>
    </StyledContainer>
  );
};

export default AvatarContainer;
