import { Box, Button, Typography, CircularProgress } from "@mui/material";
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { useDocument } from "@/hooks";
import { theme } from "@/theme";

import { IPDFViewerProps } from "./PDFViewer.types";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFViewer: React.FC<IPDFViewerProps> = ({ base64 }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [scale, setScale] = useState<number>(1);
  const [pageWidth, setPageWidth] = useState<number>(0);
  const { setDocumentSignersPage } = useDocument();

  useEffect(() => {
    ref.current && setPageWidth(ref.current.offsetWidth);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy): void => {
    setDocumentSignersPage(numPages + 1);
    setNumPages(numPages);
  };

  const onPageLoadSuccess = ({ view }: PDFPageProxy): void => {
    const originaWidth = view[2];
    let getScale = pageWidth && originaWidth ? pageWidth / originaWidth : 1;
    getScale *= 2.5;
    setScale(getScale);
  };

  const handlePagination = (target: "prev" | "next"): void => {
    switch (target) {
      case "prev":
        setPage(prev => prev - 1);
        break;
      case "next":
        setPage(prev => prev + 1);
        break;
    }
  };

  return (
    <Box ref={ref}>
      <Box
        maxHeight={700}
        overflow='auto'
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='flex-start'
      >
        <Document file={base64} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={page}
            renderMode='canvas'
            renderTextLayer={false}
            renderAnnotationLayer={false}
            loading={<CircularProgress />}
            onLoadSuccess={onPageLoadSuccess}
            width={pageWidth}
            scale={scale}
          />
        </Document>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
        gap={theme.spacing(2)}
        paddingTop={theme.spacing(2)}
      >
        <Button
          variant='contained'
          onClick={() => handlePagination("prev")}
          disabled={page === 1}
        >
          Página anterior
        </Button>
        <Typography variant='body2'>
          {page} de {numPages}
        </Typography>
        <Button
          variant='contained'
          onClick={() => handlePagination("next")}
          disabled={page === numPages}
        >
          Página siguiente
        </Button>
      </Box>
    </Box>
  );
};

export default PDFViewer;
