import { Theme, lighten } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
    },
    summaryContainer: {
      p: theme.spacing(2),
      backgroundColor: lighten(theme.palette.atmosphere?.main ?? "", 0.9),
      borderWidth: 1,
      borderStyle: "dotted",
      borderColor: theme.palette.atmosphere?.main,
    },
    crumIconContainer: {
      borderRadius: theme.spacing(1),
      height: 30,
      width: 30,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }),
);
