import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { v4 as uuid } from "uuid";

import {
  ISliceDocumentState,
  IDocumentSigner,
  EDocumentTypes,
  EDocumentCreationSteps,
} from "./slice.types";

const initialState: ISliceDocumentState = {
  documentCreationStep: null,
  documentType: null,
  documentOrderInfo: null,
  documentSigners: [],
  documentUrl: null,
  documentIsDone: false,
  documentBase64: null,
  documentSigningPage: null,
  documentsCreated: null,
  error: false,
  contractTypes: [],
};

export const procedureSlice = createSlice({
  initialState,
  name: "document",
  reducers: {
    setDocumentSigningPage: (
      state: ISliceDocumentState,
      { payload }: PayloadAction<number>,
    ) => {
      state.documentSigningPage = payload;
    },
    setDocumentBase64: (
      state: ISliceDocumentState,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.documentBase64 = payload;
    },
    setDocumentCreationStep: (
      state: ISliceDocumentState,
      { payload }: PayloadAction<EDocumentCreationSteps | null>,
    ) => {
      state.documentCreationStep = payload;
    },
    setDocumentType: (
      state: ISliceDocumentState,
      { payload }: PayloadAction<EDocumentTypes | null>,
    ) => {
      state.error = false;
      state.documentType = payload;
    },
    setDocument: (
      state: ISliceDocumentState,
      {
        payload,
      }: PayloadAction<{
        documentOrderInfo: ISliceDocumentState["documentOrderInfo"];
      }>,
    ) => {
      state.error = false;
      state.documentOrderInfo = payload.documentOrderInfo && {
        ...payload.documentOrderInfo,
      };
    },
    setSigners: (
      state: ISliceDocumentState,
      { payload }: PayloadAction<IDocumentSigner>,
    ) => {
      const parsePayload = { ...payload, id: uuid() };
      const isNotUniq = state.documentSigners.some(
        sg => sg.id === parsePayload.id,
      );

      if (state.documentSigners) {
        if (isNotUniq) {
          state.error = true;
        } else {
          state.error = false;
          const parsePayload = { ...payload, id: uuid() };
          state.documentSigners = [...state.documentSigners, parsePayload];
        }
      } else {
        state.error = false;
        const parsePayload = { ...payload, id: uuid() };
        state.documentSigners = [parsePayload];
      }
    },
    removeSigner: (
      state: ISliceDocumentState,
      { payload }: PayloadAction<string>,
    ) => {
      state.error = false;
      state.documentSigners = state.documentSigners.filter(
        signer => signer.id !== payload,
      );
    },
    updateSigner: (
      state: ISliceDocumentState,
      { payload }: PayloadAction<{ prevId: string; data: IDocumentSigner }>,
    ) => {
      const copy = cloneDeep(state.documentSigners);
      const signer = copy.findIndex(sg => sg.id === payload.prevId);
      copy[signer] = payload.data;
      state.documentSigners = copy;
    },
    setDocumentsCreated: (
      state: ISliceDocumentState,
      { payload }: PayloadAction<ISliceDocumentState["documentsCreated"]>,
    ) => {
      state.documentsCreated = payload;
    },
    clearDocument: (state: ISliceDocumentState) => {
      state.documentCreationStep = null;
      state.documentType = null;
      state.documentOrderInfo = null;
      state.documentSigners = [];
      state.documentUrl = null;
      state.documentIsDone = false;
      state.documentBase64 = null;
      state.documentSigningPage = null;
      state.error = false;
      state.contractTypes = [];
    },
    setError: (
      state: ISliceDocumentState,
      { payload }: PayloadAction<ISliceDocumentState["error"]>,
    ) => {
      state.error = payload;
    },
    setContractTypes: (
      state: ISliceDocumentState,
      { payload }: PayloadAction<ISliceDocumentState["contractTypes"]>,
    ) => {
      state.contractTypes = payload;
    },
  },
});

export const {
  setDocumentCreationStep,
  setDocumentType,
  setDocument,
  setSigners,
  removeSigner,
  updateSigner,
  clearDocument,
  setDocumentBase64,
  setDocumentsCreated,
  setDocumentSigningPage,
  setError,
  setContractTypes
} = procedureSlice.actions;
